<template>
  <section class="section__container">
    <div class="section__container_background">
      <v-img
        :cover="true"
        max-width="100vw"
        max-height="100%"
        src="/backgrounds/page_quemsomos_caritasbrasil.png"
        alt=""
      />
      <GoBack />
    </div>
    <div class="container position--relative">
      <Breadcrumb class="sobre_section_bc-color">
        <template v-slot:breadcrumbLinks>
          <router-link
            tag="p"
            :to="{ name: link.href }"
            v-for="(link, idx) in breadcrumbItems"
            :key="idx"
          >
            {{ link.text }}
          </router-link>
        </template>
      </Breadcrumb>

      <v-row>
        <v-col sm="12" md="7">
          <Card
            title="Cáritas Brasileira"
            borderColor="#C92F05"
            titleColor="#C92F05"
            backgroundColor="#F9F7ED"
          >
            <template v-slot:conteudo>
              <p>
                A Cáritas Brasileira, fundada em 1956, é uma das 164
                organizações membros da Rede Cáritas Internacional presentes em
                várias partes do mundo. No Brasil, a instituição conta com uma
                rede de mais de 15 mil colaboradores, entre contratados e
                voluntários, que atuam de forma articulada em vários projetos em
                diversas partes do país.
              </p>
              <p>
                Nacionalmente, a Cáritas é um organismo da Conferência Nacional
                dos Bispos do Brasil (CNBB) e está organizada em uma rede com
                183 entidades membros, sendo estas, 12 regionais – Rio Grande do
                Sul, Santa Catarina, Paraná, São Paulo, Minas Gerais, Espírito
                Santo, Norte II (Amapá e Pará), Maranhão, Piauí, Ceará, Nordeste
                II (Alagoas, Paraíba, Pernambuco e Rio Grande do Norte) e
                Nordeste III (Bahia e Sergipe) – e uma sede nacional localizada
                em Brasília. A atuação da Cáritas Brasileira é orientada por
                temáticas variadas, tais como o fomento às iniciativas de
                Economia Solidária, à Segurança Alimentar e Nutricional, a
                Fundos Solidários, envolvendo jovens, mulheres, catadores de
                materiais recicláveis, pequenos agricultores, acampados e
                assentados de reforma agrária, ribeirinhos, quilombolas e
                indígenas, comunidades em situação de riscos e atingidas por
                desastres socioambientais.
              </p>
              <p>
                A Cáritas Brasileira no início de sua fundação ficou incumbida
                de articular todas as obras sociais da Igreja Católica no país,
                assim, assumiu a distribuição do Programa de Alimentos para a
                Paz subsidiado pelo governo estadunidense. Ao fim do
                financiamento deste programa, as equipes da Cáritas adequaram-se
                às mudanças previstas e encontraram um novo rumo em torno de
                ideias da educação de base e da promoção humana no lugar do
                assistencialismo. Tal ideia já estava presente e discutida no
                Encontro Episcopal de Medellín na Colômbia (1968) e foi adotada
                por diversos movimentos sociais na década, sobretudo, no
                continente latinoamericano. Assim, deu-se, o que pode ser
                interpretado como um processo de renascimento da Cáritas
                Brasileira, apoiado, sobretudo, nas orientações sociais do
                Concílio Vaticano II e da Conferência de Medellín.
              </p>
              <p>
                Já nos anos 1980 vemos surgir no Brasil, como em várias
                organizações da América Latina, novas metodologias para a
                mudança social. O ambiente da educação popular trabalhado nas
                Comunidades Eclesiais de Base (CEB’s) e nos Organismos e
                Pastorais Sociais como o Conselho Indigenista Missionário
                (CIMI), a Comissão Pastoral da Terra (CPT) e a Comissão Pastoral
                Operária (CPO) favoreceu a imersão na realidade da população
                brasileira para a proposição de formas de transformação. Foi
                este o caso da Cáritas, inspirada, sobretudo, no movimento da
                Teologia da Libertação.
              </p>
              <p>
                Ressalta-se que, embora a Cáritas tenha sido construída por
                voluntários e voluntárias, ela conta com um quadro fixo de
                pessoas que, diante do crescimento, passou a assumir o
                planejamento, monitoramento, avaliação e sistematização (PMAS) e
                a busca pela gestão, comunicação, sustentabilidade institucional
                e formação dos agentes. Assim foram sendo criadas as Comissões,
                Grupos Gestores, Grupos de Trabalho, Conselhos, Fóruns e
                Coordenações Colegiadas.
              </p>
            </template>

            <template v-slot:footerImage>
              <div class="card__footer_img mt-3">
                <img width="50%" src="/logos/logo_brasileira.png" alt="" />
              </div>
            </template>
          </Card>
        </v-col>
        <div class="container">
          <v-row>
            <v-col sm="12" md="7" class="card__footer_btn">
              <div
                @click="navigateTo('sobre_caritas_regional')"
                class="white--text pa-3 nav__button_intern"
              >
                Conhecer "Cáritas Brasileira Regional Minas Gerais"
              </div>
            </v-col>
          </v-row>
        </div>
      </v-row>
    </div>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";

import GoBack from "@/components/GoBack.vue";

export default {
  components: {
    Card,
    Breadcrumb,
    GoBack,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Quem somos /",
          href: "cards",
        },

        {
          text: "Cáritas Regional Minas Gerais /",
          href: "sobre_caritas_regional",
        },
        {
          text: "Cáritas Brasileira",
          href: "sobre_caritas_brasileira",
        },
      ],
    };
  },

  methods: {
    navigateTo(route) {
      return this.$router.push({ name: route });
    },
  },
};
</script>

<style scoped>
.v-btn {
  width: 80%;
  word-wrap: normal;
  white-space: nowrap;
  text-align: center;
}
.section__container {
  height: 60%;
  width: 100%;
  position: relative;
}

.section__container_background {
  position: fixed;
  top: 0;
}

@media (max-width: 600px) {
  .section__container_background {
    position: relative;
  }
}

.position--relative {
  position: relative;
}

.card__footer_img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card__footer_img img {
  width: 40%;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.sobre_section_bc-color p:last-child {
  color: #c92f05;
}
</style>
